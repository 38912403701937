<template>
  <v-container>
    <!-- <v-btn color="secondary" text @click="checkPrueba">Ver</v-btn> -->
    <div class="container-global">
      {{clientSecret}}
      <v-form id="payment-form" @submit.prevent="paymentSubmit()">
        <v-row>
          <v-col class="col-6">
            <v-row>
              <v-col col="12" sm="12" md="4">
                <div class="card-element">
                  <div id="card-number-element"></div>
                </div>
              </v-col>
              <v-col col="12" sm="12" md="4">
                <div class="card-element">
                  <div id="card-expiry-element"></div>
                </div>
              </v-col>
              <v-col col="12" sm="12" md="4">
                <div class="card-element">
                  <div id="card-cvc-element"></div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <p class="errorColor--text mt-4 mb-4">{{stripeValidationError}}</p>
        <v-btn type="submit" color="secondary">Pagar</v-btn>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {
  firebase,
  auth,
  db,
  storage,
  marcaTiempo,
  functions
} from "@/firebase";
export default {
  data() {
    return {
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      stripeValidationError: "",
      clientSecret: ""
    };
  },
  mounted() {
    this.stripe = Stripe("pk_test_azBHtHdL6mG4jT4ll5r9Q6Py00w8vbdexD");
    this.createAndMountFormElements();
  },
  methods: {
    ...mapActions("adminUsers", ["checkOutPrediseñada"]),
    async createAndMountFormElements() {
      // var checkPaymentIntent = firebase
      //   .functions()
      //   .httpsCallable("createPaymentIntent");
      // const result = await checkPaymentIntent();
      // this.clientSecret = result.data.clientSecret;
      var style = {
        base: {
          iconColor: "#c4f0ff",
          color: "#87BBFD",
          fontWeight: "500",
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883"
          },
          "::placeholder": {
            color: "#87BBFD"
          }
        },
        invalid: {
          fontFamily: "Arial, sans-serif",
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      var elements = this.stripe.elements();
      this.cardNumberElement = elements.create("cardNumber", {
        style: style
      });
      this.cardNumberElement.mount("#card-number-element");
      this.cardExpiryElement = elements.create("cardExpiry", {
        style: style
      });
      this.cardExpiryElement.mount("#card-expiry-element");
      this.cardCvcElement = elements.create("cardCvc", { style: style });
      this.cardCvcElement.mount("#card-cvc-element");
      this.cardNumberElement.on("change", this.setValidationError);
      this.cardExpiryElement.on("change", this.setValidationError);
      this.cardCvcElement.on("change", this.setValidationError);
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : "";
    },
    paymentSubmit() {
      // this.stripe
      //   .confirmCardPayment(this.clientSecret, {
      //     payment_method: {
      //       card: this.cardNumberElement
      //     }
      //   })
      //   .then(function(result) {
      //     console.log(result)
      //     if (result.error) {
      //       console.log(result.error.message);
      //     } else {
      //       console.log(
      //         "https://dashboard.stripe.com/test/payments/" +
      //           result.paymentIntent.id
      //       );
      //     }
      //   });
    }
  }
};
</script>

<style lang="scss">
.card-element {
  background: white;
  padding: 10px;
  border: 1px solid #87bbfd;
}
</style>